import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

window.React = React;
window.ReactDOM = ReactDOM;
window.MyReactApp = App;

if (document.getElementById('root')) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
