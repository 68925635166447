import React from 'react';
import './Opcao.css';

export default function Opcao({ opcao }) {
  return (
    <div className='form-checkbox'>
      <label>
        <input type="checkbox" name="checkbox" />
        <span>{opcao}</span>
      </label>
    </div>
  );
}
