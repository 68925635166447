import React from 'react';
import './Parallax.css';
import parallaxTop from '../../assets/images/parallax-border1.svg'
import parallaxBot from '../../assets/images/parallax-border2.svg'

export default function Parallax() {
  return (
    <>
      <section class="parallax-container">
        
        <div class="parallax"></div>
        
      </section>
      <section class="parallax-content">
        
      </section>
    </>
  )
}
