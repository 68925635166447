import Header from 'components/Header';
import React from 'react';
import './QuemSomos.css'
import Footer from 'components/Footer';
import banner1 from '../../assets/images/banner_quemsomos.png'
import banner2 from '../../assets/images/banner_quemsomos2.png'
import banner3 from '../../assets/images/banner_quemsomos3.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import EtapaDireita from '../../components/EtapaDireita';
import imagem from '../../assets/images/robo.png';
import EtapaEsquerda from 'components/EtapaEsquerda';


export default function QuemSomos() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return (
    <>
      <Header />
      <div className='banner banner-principal-quemsomos'>
        <h1 className='banner-h1' data-aos="fade-down" data-aos-duration="1500">Quem somos</h1>
        <img src={banner1} alt="" />
      </div>


      <div className='produtos-text-area'>
        <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">
          A Amado Maker é uma empresa de tecnologia para educação. É especializada na elaboração de material didático direcionado para a Cultura Maker e implementação de tecnologia educacional, como a instalação de Espaços Makers, principalmente em escolas públicas do Brasil. Atende alunos e professores desde a Educação Infantil até a EJA. 
          <br />
          <br />
          Para o corpo docente a Amado Maker disponibiliza um treinamento técnico e pedagógico robusto, com mestres e doutores na área de Educação Maker, visando capacitar o educador para a apropriação das metodologias ativas de ensino e mantém a assessoria durante todo processo de aplicação e execução do projeto.
        </p>
      </div>

      <div className='banner quemsomos-banner'>
        <h1 className='banner-h1' data-aos-once='true' data-aos="fade-down" data-aos-duration="1500">Nossa história</h1>
        <img src={banner2} alt="" />
      </div>

      <div className='produtos-text-area'>
        <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">
          A trajetória pelo universo maker começou em 2014, na feira de tecnologia BETT Show, em Londres, quando o criador da empresa, Marcelo Amado, teve seu primeiro contato com as impressoras 3D. Com o passar do tempo e com o aumento do conceito Faça Você Mesmo, Marcelo montou um Fab Lab showroom em Indaiatuba - SP em 2019 com o objetivo de oferecer às escolas, públicas ou particulares, a consultoria e montagem de Sala Maker, com atuação em todo território nacional.
          <br /><br />
          
          Neste contexto surgiu a necessidade da formatação de um material didático específico para as atividades mão na massa, dentro das Salas Maker ou Fab Labs. Assim surgiu a AMADO MAKER Editora, a qual contratou uma equipe pedagógica especializada na área de Educação Maker e criou o material didático Espiral, baseado na BNCC e nos quatro pilares da Unesco.
          <br /><br />
          
          Atualmente a empresa conta com três linhas de materiais didáticos principais, além da formação e acompanhamento técnico-pedagógico às escolas.
        </p>
      </div>

      <div className='banner quemsomos-banner'>
        <h1 className='banner-h1' data-aos="fade-down" data-aos-duration="1500">Valores</h1>
        <img src={banner3} alt="" />
      </div>
      
      <div className='produtos-text-area width-100'>
        <EtapaEsquerda
          imagem={imagem} 
          titulo={''} 
          texto={`Ter um olhar único para cada projeto, atendendo as necessidades de cada cliente.`}
        />

        <EtapaDireita
          imagem={imagem} 
          titulo={''} 
          texto={`Trabalhar com respeito, ética e responsabilidade social e ambiental. `}
        />

        <EtapaEsquerda
          imagem={imagem} 
          titulo={''} 
          texto={`Manter a harmonia e o espírito colaborativo entre os funcionários e prestadores de serviço.`}
        />
      </div>
      

      <Footer/>
    </>
  )
}
