import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react';
import './BlogPost.css'

export default function BlogPost() {
  return (
    <>
      <Header/>
        <section className='section-area'>
          <div className='blog-text-area'>
            <h1 className='h1-blog'>Titulo padrão do post</h1>
            <p className='texto-padrao-blog'>Esse é um texto padrão de teste para o site da Amado Maker.</p>
            <p className='texto-padrao-blog'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores eveniet ipsa reiciendis libero natus obcaecati illo soluta repudiandae quam possimus fugit ipsam exercitationem minima, dolore quisquam! Neque, voluptas. Saepe, nam. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut odio pariatur commodi vero, quia itaque minus eaque laudantium eum et eos praesentium velit voluptatum blanditiis totam veritatis ab sapiente ad!</p>
            <p className='texto-padrao-blog'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum, modi doloremque! Dolorem, quod cumque. Velit omnis modi dignissimos. Consequuntur ratione sit neque quibusdam rem aut blanditiis quos, ad eos dicta. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime repudiandae, doloremque nesciunt minus impedit ut obcaecati exercitationem consectetur soluta eligendi. Alias soluta voluptas, magnam veniam nihil iusto velit? Veniam, reprehenderit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates eos fugiat similique nulla debitis praesentium facere, omnis, velit quod quibusdam odit animi voluptas aperiam beatae, commodi hic corporis asperiores ratione!</p>
            <p className='texto-padrao-blog'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque aperiam, eius quam quo voluptate voluptates facere aut animi eos ipsam in illo nobis, cum incidunt accusamus obcaecati odio perferendis rerum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quod, fuga eum ex ea reprehenderit voluptates deserunt commodi eius doloremque nulla id delectus harum iusto! Eum odit repudiandae natus soluta? Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui enim ut eaque, quaerat nulla dolor debitis earum ex ratione ipsum. Animi fuga hic voluptatibus at doloremque ullam nesciunt sint aliquid. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequatur unde aperiam nulla repellat, magni possimus, numquam veniam, quaerat reprehenderit cumque dolores iste mollitia molestiae omnis qui ad? Dignissimos, laudantium obcaecati! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum atque quibusdam modi, qui dolor animi porro ducimus? Delectus officiis omnis eos at vitae voluptatibus totam temporibus ipsa beatae, incidunt id.</p>
            <p className='texto-padrao-blog'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque aperiam, eius quam quo voluptate voluptates facere aut animi eos ipsam in illo nobis, cum incidunt accusamus obcaecati odio perferendis rerum. Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium quod, fuga eum ex ea reprehenderit voluptates deserunt commodi eius doloremque nulla id delectus harum iusto! Eum odit repudiandae natus soluta? Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui enim ut eaque, quaerat nulla dolor debitis earum ex ratione ipsum. Animi fuga hic voluptatibus at doloremque ullam nesciunt sint aliquid. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequatur unde aperiam nulla repellat, magni possimus, numquam veniam, quaerat reprehenderit cumque dolores iste mollitia molestiae omnis qui ad? Dignissimos, laudantium obcaecati! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum atque quibusdam modi, qui dolor animi porro ducimus? Delectus officiis omnis eos at vitae voluptatibus totam temporibus ipsa beatae, incidunt id. Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis iure voluptate obcaecati similique temporibus. Exercitationem commodi, asperiores aperiam eum temporibus qui optio ratione a harum velit. Autem reiciendis cumque odit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi non iusto ut, beatae omnis possimus. Ducimus dolores porro minima, assumenda et quo ex doloribus molestiae laboriosam! Incidunt voluptatibus cumque tenetur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi expedita eius odio, itaque dolor id voluptatum molestiae maiores voluptate pariatur enim fuga impedit dolorem quo aliquam quaerat, velit, asperiores ullam?</p>
            <p className='texto-padrao-blog'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem harum voluptatibus unde, delectus beatae at hic sit officia suscipit veniam, dignissimos ipsum praesentium velit quis quae earum aut tenetur modi. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat illo labore corrupti enim! Deleniti fugit facere vero officiis consequatur error accusamus nulla quas aliquid voluptas? Incidunt corrupti adipisci nostrum hic. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos laudantium excepturi autem eveniet voluptatem asperiores modi ea veniam velit perspiciatis impedit quaerat, placeat sapiente rem assumenda veritatis dolor debitis? Eveniet?</p>
          </div>
          
        </section>
      <Footer/>
    </>
  )
}
