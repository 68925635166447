import React, { useEffect } from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import './SalaEspiral.css';
import banner from '../../../assets/images/banner-sala-espiral.png'
import BotaoGrande from 'components/BotaoGrande';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ilustracoes from '../../../assets/images/ilustracoes.png'



export default function SalaEspiral() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);


  return (
    <>
      <Header/>
        <section className='section-area'>
          <div className='produtos-text-area'>
            <h1 className='produtos-h1' data-aos="fade" data-aos-duration="1500">Sala Espiral</h1>
            <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">A Sala Maker Espiral é projetada de acordo com a disposição de espaço da escola. A metragem mínima é de 50 metros quadrados para atendimento de até 16 alunos simultaneamente.<br/><br/>
              
              O objetivo do espaço é promover a aprendizagem de forma interdisciplinar baseada em resolução de problemas e projetos. O ambiente é equipado com impressora 3D, cortadora a laser e plotter de recorte. A Amado Maker entrega o ambiente completo com mesas, cadeiras, bancadas, painel de ferramentas, painel kerf. Lembrando que cada escola pode personalizar seu ambiente de acordo com suas necessidades.<br/><br/>
              
              Junto com o espaço maker a escola recebe o material didático Espiral que norteia as aulas. Os professores também recebem treinamento da nossa equipe pedagógica.</p>
          </div>

          <div className='produtos-video' data-aos="fade" data-aos-duration="1500">
            <iframe width="100%" height="720" src="https://www.youtube.com/embed/hBmxHefKhpE" title="Sala Maker Espiral" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>

          <div className='produtos-text-area'>
            <h2 className='produtos-h2' data-aos="fade-down" data-aos-duration="1500">Espiral</h2>
            <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">O Currículo Espiral é um material escolar de âmbito complementar, orientado ao desenvolvimento de habilidades e competências para o século XXI apoiado nos ambientes de prototipagem e fabricação digital.<br/><br/>
            
            O material foi constituído através de uma espiral progressiva de conhecimento, o qual o aluno tem contato com as 10 competências da BNCC juntamente dos Pilares da UNESCO, todos os anos de forma interdisciplinar, desenvolvendo gradualmente sua complexidade e desafios a cada ciclo, no intuito de formar pessoas mais preparadas para trabalhar para as profissões do futuro.</p>
          </div>


        </section>

        <div className='banner-salaespiral'>
          <div className='banner-content'>
            <img src={ilustracoes} alt="" />
          </div>
          <img src={banner} alt="" />
        </div>

        <div className='produtos-text-area'>
            <h2 className='produtos-h2' data-aos="fade-down" data-aos-duration="1500">Como funcionam os projetos?</h2>
            <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">Os projetos são direcionados para serem executados no Laboratório de Projetos, outro nome que pode ser utilizado para a sala maker, o qual permite ao aluno ter contato com novas tecnologias, utilizando-as de forma ética e responsável, para desenvolver as atividades propostas em cada bimestre. <br /><br />
            
            Todas as atividades são realizadas em grupo, com o intuito de desenvolver competências colaborativas, troca de ideias e informações, valorizando a tentativa e erro no processo de aprendizagem.<br /><br />
            
            As aulas são preparadas para uma duração mínima de 100 minutos, dividida em Introdução, Construção e Teste, Registro, Compartilhamento e Organização, trazendo temas trabalhados na grade curricular daquele ano. Não é necessário trabalhar o livro de forma linear, os professores têm a liberdade de apresentar o projeto de forma mais condizente com o bimestre trabalhado.<br />
            Cada período escolar tem uma bagagem diferente, as quais são trabalhadas através de ciclos.<br />
            Os ciclos 1 e 2 são relacionados ao Fundamental 1, possuem a produção voltada ao impacto individual. A criança usa a criação para aprender enquanto se diverte, conhecendo medidas do próprio corpo, gostos pessoais e exploração de forma instintiva. Nos ciclos 3 e 4, relacionados ao Fundamental 2, a produção possui impacto em outras pessoas que usarão o artefato construído, levando o aluno a projetar para um usuário que pensa e age diferente dele.<br /><br />
            
            Já no ciclo 5, referente ao Ensino Médio, os projetos já possuem uma abrangência global, afim de solucionar problemas que impactam na sociedade do aluno de forma mais ampla, como outros bairros, cidades ou países. O projeto realizado passa a ter mais fundamentos práticos aprendidos dentro de sala de aula e materializados no laboratório.
            </p>
          </div>

          <div className='produtos-botao'>
            <Link to="/contato"><BotaoGrande>Entre em contato conosco</BotaoGrande></Link>
          </div>
          

      <Footer/>
    </>
  )
}