import React from 'react';
import './OndeAtuamos.css'
import Header from 'components/Header';
import Footer from 'components/Footer';
import banner from '../../assets/images/banner-onde-atuamos.png';
import mapa from '../../assets/images/mapa.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import BotaoGrande from 'components/BotaoGrande';
import { Link } from 'react-router-dom';


export default function OndeAtuamos() {

  useEffect(()=> {
    AOS.init()
  },[]);

  return (
    <>
      <Header />
      <div className='banner'>
        <h1 className='banner-h1' data-aos="fade-down" data-aos-duration="1500">Onde atuamos</h1>
        <img src={banner} alt="" />
      </div>
      <section className='section-area'>
        <div className='onde-atuamos-area'>
          <img src={mapa} alt="" />
        </div>
        <div className='btn'>
          <Link to='/contato'><BotaoGrande>Leve-nos para a sua cidade!</BotaoGrande></Link>
        </div>
        
      </section>
      <Footer />
    </>
  )
}
