import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import whatsappBtn from '../../assets/images/whatsapp.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function WhatsappBtn() {

  useEffect(()=> {
    AOS.init()
  },[]);

  return (
    <>
      <div id='whatsapp' data-aos="fade" data-aos-duration="500" >
        <Link to="https://wa.me/5519998674163" target='_blank' style={{ textDecoration: 'none' }}><img src={whatsappBtn} alt="" /></Link>
      </div>
    </>
  )
}
