import React, { useEffect, useState } from 'react';
import './Header.css';
import logo from '../../assets/images/logo.svg';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa';

export default function Header() {
  const location = useLocation();
  const [isProdutosActive, setIsProdutosActive] = useState(false);
  const [isQuemSomosActive, setIsQuemSomosActive] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const header = document.getElementById('myHeader');
    const sticky = header.offsetTop;

    window.addEventListener('scroll', () => handleScroll(sticky));

    return () => {
      window.removeEventListener('scroll', () => handleScroll(sticky));
    };
  }, []);
    
  function handleScroll(sticky) {
    const header = document.getElementById('myHeader');

    if (window.scrollY > sticky) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }

  useEffect(() => {
    const produtosPaths = [
      '/sala-espiral',
      '/espiral-maker-desplugado',
      '/espiral-trilhas-de-aprendizagem',
      '/plataforma-colaborativa',
      '/formacao-tecnica-e-pedagogica'
    ];
    setIsProdutosActive(produtosPaths.some(path => location.pathname.startsWith(path)));

    const quemSomosPaths = [
      '/Quem-somos',
      '/nossa-equipe'
    ];
    setIsQuemSomosActive(quemSomosPaths.some(path => location.pathname.startsWith(path)));
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavClick = () => {
    if (window.innerWidth <= 1022) {
      setMobile(false);
    }
  };

  const toggleMobileMenu = () => {
    setMobile(!mobile);
  };

  return (
    <header id='myHeader'>
      <section className='header-area'>
        <div className="logo">
          <Link to='/'><img src={logo} alt="Logo" /></Link>
        </div>
        <nav>
          <ul className={mobile ? "header-list-mobile" : "header-list"}>
            <li className="nav-li">
              <NavLink to='/' activeClassName="ativo" exact onClick={handleNavClick}>Home</NavLink>
            </li>
            <li className="nav-li">
              <div className={isQuemSomosActive ? 'ativo li-drop' : 'li-drop'}>
                Quem somos
                <ul className='dropdown-quemsomos'>
                  <li><NavLink to="/Quem-somos" activeClassName="ativo" onClick={handleNavClick}>Nossa história</NavLink></li>
                  <li><NavLink to="/nossa-equipe" activeClassName="ativo" onClick={handleNavClick}>Nossa equipe</NavLink></li>
                </ul>
              </div>
            </li>
            <li className="nav-li"><NavLink to='/onde-atuamos' activeClassName="ativo" onClick={handleNavClick}>Onde atuamos</NavLink></li>
            <li className="nav-li nav-prod">
              <div className={isProdutosActive ? 'ativo li-drop' : 'li-drop'}>
                Produtos
                <ul className='dropdown-produtos'>
                  <li><NavLink to="/sala-espiral" activeClassName="ativo" onClick={handleNavClick}>Sala Espiral</NavLink></li>
                  <li><NavLink to="/espiral-maker-desplugado" activeClassName="ativo" onClick={handleNavClick}>Espiral Maker Desplugado</NavLink></li>
                  <li><NavLink to="/espiral-trilhas-de-aprendizagem" activeClassName="ativo" onClick={handleNavClick}>Espiral Trilhas de aprendizagem</NavLink></li>
                  <li><NavLink to="/plataforma-colaborativa" activeClassName="ativo" onClick={handleNavClick}>Plataforma colaborativa</NavLink></li>
                  <li><NavLink to="/formacao-tecnica-e-pedagogica" activeClassName="ativo" onClick={handleNavClick}>Formação técnica e pedagógica</NavLink></li>
                </ul>
              </div>
            </li>
            <li className="nav-li"><NavLink to='/Blog' activeClassName="ativo" onClick={handleNavClick}>Blog</NavLink></li>
            <li className="nav-li"><NavLink to='/Contato' activeClassName="ativo" onClick={handleNavClick}>Contato</NavLink></li>
            <li className="nav-li"><NavLink to='/Parceiros' activeClassName="ativo" onClick={handleNavClick}>Parceiros</NavLink></li>
            <li className="nav-li"><NavLink to='/Orcamento' activeClassName="ativo" onClick={handleNavClick}>Orçamento</NavLink></li>
          </ul>

          <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
            {mobile ? <AiOutlineClose size="24px" color="#F58634"/> : <FaBars size="24px" color="#F58634"/>}
          </button>
        </nav>
      </section>
    </header>
  );
}

