import Botao from 'components/Botao';
import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className='notfound'>
      <h1>Página Não Encontrada</h1>
      <p>A página que você está procurando não existe.</p>
      <Link to="/"><Botao>Voltar</Botao></Link>
    </div>
  );
}

export default NotFound;
