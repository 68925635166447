import React from "react";
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import './App.css';
import Produtos from './pages/Produtos';
import Sobre from './pages/QuemSomos';
import Contato from './pages/Contato';
import Blog from './pages/Blog';
import Parceiros from './pages/Parceiros';
import Orcamento from './pages/Orcamento';
import SalaEspiral from './pages/Dropdown/SalaEspiral';
import EspiralMaker from './pages/Dropdown/EspiralMaker';
import EspiralTrilhas from './pages/Dropdown/EspiralTrilhas';
import PlataformaColaborativa from './pages/Dropdown/PlataformaColaborativa';
import FormacaoTecnica from './pages/Dropdown/FormacaoTecnica';
import NossaEquipe from './pages/Dropdown/NossaEquipe';
import OndeAtuamos from './pages/OndeAtuamos';
import BlogPost from "pages/BlogPost";
import NotFound from './pages/NotFound';

const client = new ApolloClient({
  link: new HttpLink({ uri: "http://localhost/amado_maker/?graphql" }),
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/produtos' element={<Produtos />} />
          <Route path='/quem-somos' element={<Sobre />} />
          <Route path='/contato' element={<Contato />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/parceiros' element={<Parceiros />} />
          <Route path='/orcamento' element={<Orcamento />} />
          <Route path='/onde-atuamos' element={<OndeAtuamos />} />
          <Route path='/sala-espiral' element={<SalaEspiral />} />
          <Route path='/espiral-maker-desplugado' element={<EspiralMaker />} />
          <Route path='/espiral-trilhas-de-aprendizagem' element={<EspiralTrilhas />} />
          <Route path='/plataforma-colaborativa' element={<PlataformaColaborativa />} />
          <Route path='/formacao-tecnica-e-pedagogica' element={<FormacaoTecnica />} />
          <Route path='/nossa-equipe' element={<NossaEquipe />} />
          <Route path='/post/id' element={<BlogPost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;