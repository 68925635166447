import BotaoGrande from 'components/BotaoGrande'
import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import './EspiralMaker.css'

export default function EspiralMaker() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);


  return (
    <>
      <Header/>
      <section className='section-area'>
        <div className='produtos-text-area'>
          <h1 className='produtos-h1 espiral-h1' data-aos="fade" data-aos-duration="1500">Espiral Maker Desplugado</h1>
          <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">
          A caixa Espiral Maker Desplugado surgiu como uma solução para as escolas que não possuem espaço para implantar uma sala maker com os equipamentos para a fabricação digital. É um exclusivo material de apoio no qual o professor é capaz de ensinar conceitos de mecânica, elétrica, eletrônica e programação em um só lugar, de uma forma gradativa, dinâmica e divertida. <br /><br />
          
          O objetivo é desenvolver competências exercitando novas possibilidades de aprendizagem com experiências lúdicas que estimulem a criatividade, as relações sociais, autonomia, explorando emoções e habilidades considerando o interesse e o desenvolvimento integral das crianças e seus direitos.<br /><br />
          
          Todas as atividades possibilitam que o educador acrescente, modifique, crie ou possa construir e agregar outras formas ou regras para conduzir cada prática, tornando-as personalizáveis conforme os objetivos trabalhados.
          </p>

       
        </div>
        <div className='produtos-video' data-aos="fade" data-aos-duration="1500">
          <iframe width="100%" height="720" src="https://www.youtube.com/embed/WEkP5z6ZSlU" title="ESPIRAL MAKER DESPLUGADO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        <div className='produtos-text-area'>
            <h2 className='produtos-h2' data-aos="fade-down" data-aos-duration="1500">Curso On-Line Maker Desplugado</h2>
            <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">
            O Currículo Espiral é um material escolar de âmbito complementar, orientado ao desenvolvimento de habilidades e competências para o século XXI apoiado nos ambientes de prototipagem e fabricação digital. <br /><br />
            
            O material foi constituído através de uma espiral progressiva de conhecimento, o qual o aluno tem contato com as 10 competências da BNCC juntamente dos Pilares da UNESCO, todos os anos de forma interdisciplinar, desenvolvendo gradualmente sua complexidade e desafios a cada ciclo, no intuito de formar pessoas mais preparadas para trabalhar para as profissões do futuro.
            </p>
        </div>
      </section>

      <div className='produtos-botao'>
        <Link to="/contato"><BotaoGrande>Entre em contato conosco</BotaoGrande></Link>
      </div>









      
      <Footer/>
    </>
  )
}
