import Header from 'components/Header';
import React from 'react';
import './Contato.css';
import Footer from 'components/Footer';
import Form from 'components/Form';

export default function Contato() {
  return (
    <>
      <Header />
        
        <section className='section-area'>
          <h1 className='produtos-h1'>Leve-nos para a sua cidade!</h1>
          <Form />
        </section>
      <Footer />
    </>
    
  )
}
