import React from 'react';
import './BotaoGrande.css'

export default function BotaoGrande({ children }) {
  return (
    <button className='botaogrande-padrao'>
        {children}
    </button>
  )
}
