import React from 'react';
import './Footer.css';
import logoBranco from '../../assets/images/logo_branco.svg';
import { FaFacebookSquare, FaPhoneAlt,  } from "react-icons/fa";
import { FaInstagram, FaXTwitter , FaYoutube, FaWhatsapp, FaLinkedin } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <section className="footer-area">
        <div className="footer-content">
          <div className='footer-endereco'>
            <img src={logoBranco} alt="Logo Amado Maker" />
            <p className='texto-padrao'>
            Rua Cerqueira César, 1993<br/>
            Centro, Indaiatuba<br/>
            SP, 13330-005, Brasil<br/>
            </p>
            <p className="copyright">Copyright &copy; 2024 Todos os Diretos Reservados Amado Maker</p>
          </div>
            
          <div className='footer-contato'>
            <div className='footer-contato-div'>
              <FaPhoneAlt /><p> Telefone: (19) 3894-6129</p>
            </div>
            <div className='footer-contato-div'>
              <MdOutlineEmail /><p> contato@amadomaker.com.br</p>
            </div>
            
            
          </div>

          <div className='footer-social'>
            <Link to="https://www.facebook.com/amadomaker" target='_blank' style={{ textDecoration: 'none' }}><FaFacebookSquare color='#fff' /></Link>
            <Link to="https://www.instagram.com/amadomaker/" target='_blank' style={{ textDecoration: 'none' }}><FaInstagram color='#fff' /></Link>
            <Link to="https://twitter.com/amado_maker" target='_blank' style={{ textDecoration: 'none' }}><FaXTwitter color='#fff' /></Link>
            <Link to="https://www.youtube.com/channel/UC3H7evJ4NiBWPgl5zCH7gxA" target='_blank' style={{ textDecoration: 'none' }}><FaYoutube color='#fff' /></Link>
            <Link to="https://wa.me/5519998674163" target='_blank' style={{ textDecoration: 'none' }}><FaWhatsapp color='#fff' /></Link>
            <Link to="https://www.linkedin.com/company/amado-maker-editora/" target='_blank' style={{ textDecoration: 'none' }}><FaLinkedin color='#fff' /></Link>
          </div>

        </div>
      </section>
    </footer>
  )
}
