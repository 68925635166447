import Footer from 'components/Footer'
import Header from 'components/Header'
import ParceirosCard from 'components/ParceirosCard'
import React from 'react'
import img from '../../assets/images/logo.svg'
import './Parceiros.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function Parceiros() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return (
    <>
    <Header />
    <h1 className='produtos-h1 parceiros-h1' data-aos="fade" data-aos-duration="1500">Conheça nossos parceiros!</h1>   
    <section className='section-area parceiros-area'>
      
      <ParceirosCard
        nomeDoParceiro={'Amado Maker Editora'}
        logoDoParceiro={img}
        link={'https://amadomaker.com.br/'}
      />
      <ParceirosCard
        nomeDoParceiro={'Amado Maker Editora'}
        logoDoParceiro={img}
        link={'https://amadomaker.com.br/'}
      />
      <ParceirosCard
        nomeDoParceiro={'Amado Maker Editora'}
        logoDoParceiro={img}
        link={'https://amadomaker.com.br/'}
      />
      <ParceirosCard
        nomeDoParceiro={'Amado Maker Editora'}
        logoDoParceiro={img}
        link={'https://amadomaker.com.br/'}
      />
    </section>

    <Footer/>
    </>
  )
}
