import React from 'react'
import Footer from 'components/Footer'
import Header from 'components/Header'
import banner from '../../../assets/images/banner-formacao.png'
import bannerContent from '../../../assets/images/ilustracoes.png'
import './FormacaoTecnica.css'
import cardImage from '../../../assets/images/placeholder-formacao.png'
import cardImage2 from '../../../assets/images/placeholder-formacao2.png'
import BotaoGrande from 'components/BotaoGrande'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


export default function FormacaoTecnica() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return (
    <>
      <Header/>
        <section className='section-area'>
          <div className='produtos-text-area'>
            <h1 className='produtos-h1 formacao-h1' data-aos="fade" data-aos-duration="1500">Formação Técnica e Pedagógica</h1>
            <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">
            Um dos pontos fortes da Amado Maker é o treinamento para a formação dos educadores que têm um papel fundamental para a implantação da Cultura Maker nas Escolas. <br /><br />
            
            A Amado Maker possui um programa de capacitação completo o qual habilita o professor para a prática das atividades mão na massa e o direciona para ser um multiplicador das atividades juntos aos alunos.
            </p>
          </div>
        </section>
        <div className='banner-formacao'>
          <div className='banner-content'>
            <img src={bannerContent} alt="" />
          </div>
          <img className='faixa' src={banner} alt="" />
        </div>

        <section className='section-area'>

          <div className='formacao-card-left' data-aos="fade" data-aos-duration="1500">
            <img data-aos="slide-right" data-aos-duration="1000" src={cardImage} alt="" />
            <div className='formacao-card-text'>
              <h1 data-aos="fade-up" data-aos-duration="1500">Formação Técnica Para Fabricação Digital</h1>
              <p data-aos="fade-up" data-aos-duration="1500">Além da formação pedagógica oferecemos a formação técnica para a fabricação digital em Impressora 3D, Cortadora a Laser e Plotter de Recorte, que são as máquinas utilizadas nas Salas Makers.</p>
            </div>  
          </div>

          <div className='formacao-card-right width-100' data-aos="fade" data-aos-duration="1500">
            <div className='formacao-card-text'>
              <h1 data-aos="fade-up" data-aos-duration="1500">Assessoria e acompanhamento pedagógico</h1>
              <p data-aos="fade-up" data-aos-duration="1500">Após os treinamentos, quando os professores estiverem prontos para iniciar as aulas, a equipe pedagógica acompanha a escola em toda trajetória de iniciação do material. O objetivo é dar segurança para os professores e responder às dúvidas que surgem durante o dia a dia da aplicação do material.</p>
            </div>

            <img data-aos="slide-left" data-aos-duration="1000" src={cardImage2} alt="" />
          </div>

          <div className='btn-formacao'>
            <Link to="/contato"><BotaoGrande>Entre em contato conosco</BotaoGrande></Link>
          </div>
          

        </section>

  
            
      <Footer/>
    </>
  )
}