import React, { useEffect } from 'react';
import './HomePage.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VideoHome from 'components/VideoHome';
import robo from '../../assets/images/robo.png'
import BotaoGrande from 'components/BotaoGrande';
import retangulo from '../../assets/images/retangulotest.png';
import mapaBrasil from '../../assets/images/mapa.png';
import { Link } from 'react-router-dom';
import WhatsappBtn from 'components/WhatsappBtn';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Parallax from 'components/Parallax';

export default function HomePage() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return (
    <>
      <VideoHome/>
      <Header/>
      <section className='section-area'>
        <section className='home-area'>
          <div className='home-texto-area'>
            <div className='home-texto'>
                <h1 className='h1-home' data-aos='fade-right' data-aos-duration="1500">Amado Maker</h1>
                <p className='texto-padrao texto-home' data-aos='fade-right' data-aos-duration="1500">A Amado Maker é uma empresa de tecnologia para educação. É especializada na elaboração de material didático direcionado para a Cultura Maker e implementação de tecnologia educacional, como a instalação de Espaços Makers, principalmente em escolas públicas do Brasil. Atende alunos e professores desde a Educação Infantil até a EJA.</p>
            </div>
            <div data-aos='fade-up' data-aos-duration="1000" className='home-btn'>
              <Link to='/contato'><BotaoGrande>Leve-nos para a sua cidade!</BotaoGrande></Link>
            </div>
          </div>
          <img data-aos='zoom-in' data-aos-duration="2000" src={robo} alt="" />
        </section>
      </section>

      
      
      <section className='parallax-home'> 
        <Parallax/>
      </section>

      <section className='section-area'>
        <section className='ultimos-posts'>
          <h1 data-aos='fade-right' data-aos-duration="1500" className='h1-home'>Blog - Últimas postagens</h1>
        </section>
      </section>

      <section className='projetos-home'>
        <iframe width="100%" height="720" src="https://www.youtube.com/embed/W-8hWeserGA" title="ENTREVISTA PREFEITO: O primeiro projeto em Elias Fausto" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <div className='home-btnprodutos'>
          <Link to='/sala-espiral'><BotaoGrande>Veja nossos produtos</BotaoGrande></Link>
        </div>
        
      </section>

      <section className='section-area'>
        <section className='localizacao-home width-100'>
          <div className='home-texto-area'>
            <div className='home-texto'>
              <h1 data-aos='fade-right' data-aos-duration="1500" className='h1-home'>Onde atuamos</h1>
              <p className='texto-padrao texto-home' data-aos='fade-right' data-aos-duration="1500">Atualmente, a sede da Amado Maker se encontra na Rua Cerqueira César, 1993. Centro, Indaiatuba - SP, 13330-005, Brasil</p>
            </div> 
            <div className='home-btn'>
                <Link to='/contato'><BotaoGrande>Leve-nos para a sua cidade!</BotaoGrande></Link>
              </div>
          </div>
          <img data-aos='fade' data-aos-duration="1000" src={mapaBrasil} alt="" />
        </section>
      </section>
      <WhatsappBtn/>
      

      <Footer/>
    </>
  )
}
