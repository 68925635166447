import React from 'react';
import './Form.css'

export default function Form() {
  return (
    <section className='form-area'>

      <form className='form'  id='contact'>
        <div className='form-div'>
          <label htmlFor="form-nome">Seu nome *</label>
          <input
            name='form-nome'
            className='input'
            type="text" 
            required
          />
        </div>

        <div className='form-div'>
          <label htmlFor="form-email">E-mail *</label>
          <input
            name='form-email'
            className='input'
            type="text" 
            required
          />
        </div>
        

        <div className='form-div'>
         <label>Telefone *</label>
          <input
            className='input'
            type="text" 
            required
          />
        </div>

        <div className='form-div'>
          <label>Cidade - UF *</label>
          <input
            className='input'
            type="text" 
            required
          />
        </div>
         

        <div className='form-div'>
          <label>Empresa/Escola</label>
          <input
            className='input'
            type="text"
          />
        </div>

        <div className='form-div'>
          <label>Assunto *</label>
          <input
            className='input'
            type="text" 
            required
          />
        </div>
         
        <div className='form-div'>
          <label htmlFor="form-msg">Mensagem *</label>
          <textarea
            name='form-msg'
            className='textarea'
            required  
          />
        </div>
        
        
        

        <button className='form-button' type="submit">Enviar</button>

      </form>
    </section>
  )
}
