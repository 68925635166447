import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function EtapaEsquerda({ imagem, titulo, texto }) {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return (
    <>
      <div className='trilhas-etapa etapa-esquerda'>
        <img data-aos="fade-right" data-aos-duration="2000" src={imagem} alt="" />
        <div>
          <h3 className='produtos-h3 etapa-titulo' data-aos="fade-down" data-aos-duration="2000">{titulo}</h3>
          <p className='produtos-texto etapa-texto' data-aos="fade-left" data-aos-duration="2000">
            {texto}
          </p>
        </div>
      </div>
    </>
  )
}
