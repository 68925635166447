import Header from 'components/Header';
import React from 'react';
import './Orcamento.css';
import Footer from 'components/Footer';
import Opcao from 'components/Opcao';

export default function Orcamento() {
  return (
    <>
      <Header />
      <section className='section-area'>
          <h1 className='produtos-h1 orcamento-h1'>Faça um orçamento</h1> 
      <section className='form-area'>
        <form className='form'  id='contact'>
          <div className='form-div'>
            <label htmlFor="form-nome">Seu nome *</label>
            <input
              name='form-nome'
              className='input'
              type="text" 
              required
            />
          </div>

          <div className='form-div'>
            <label htmlFor="form-email">E-mail *</label>
            <input
              name='form-email'
              className='input'
              type="text" 
              required
            />
          </div>
          

          <div className='form-div'>
          <label>Telefone *</label>
            <input
              className='input'
              type="text" 
              required
            />
          </div>

          <div className='form-div'>
            <label>Cidade - UF *</label>
            <input
              className='input'
              type="text" 
              required
            />
          </div>
          <div className='form-opcoes'>
            <div className='opcoes-direita'>
              <Opcao opcao={'Opção 1'}/>
              <Opcao opcao={'Opção 2'}/>
              <Opcao opcao={'Opção 3'}/>
              <Opcao opcao={'Opção 4'}/>
              <Opcao opcao={'Opção 5'}/>
              <Opcao opcao={'Opção 6'}/>
            </div>
            <div className='opcoes-esquerda'>
              <Opcao opcao={'Opção 1'}/>
              <Opcao opcao={'Opção 2'}/>
              <Opcao opcao={'Opção 3'}/>
              <Opcao opcao={'Essa opção tem varias palavras para teste amado maker'}/>
              <Opcao opcao={'Opção 5'}/>
              <Opcao opcao={'Opção 6'}/>
            </div>
            
            
          </div>
          
          
          <div className='form-div'>
            <label htmlFor="form-msg">Mensagem</label>
            <textarea
              name='form-msg'
              className='textarea'
            />
          </div>
          
          
          

          <button className='form-button' type="submit">Enviar</button>

        </form>
      </section>
      </section>
      <Footer />
    </>
    
  )
}
