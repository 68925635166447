import React from 'react';
import './BlogCard.css';
import Botao from 'components/Botao';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function BlogCard() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return(
    <>
      <div className='blog-card'  data-aos="fade-up" data-aos-duration="1000">
        <div className='blogcard-textos' data-aos="fade" data-aos-duration="3000">
          <h1>O que é um Fab Lab?</h1>
          <p>Conheça um pouco mais sobre Fab Labs!</p>
          <Link to='/post/id'><Botao>Ler mais</Botao></Link>
        </div>
      </div>
    </>
  )
}
