import Header from 'components/Header';
import React from 'react';
import './Blog.css';
import Footer from 'components/Footer';
import bannerBlog from '../../assets/images/banner_blog.png';
import BlogCard from 'components/BlogCard';


export default function Blog() {
  return (
    <>
      <Header />
      <div className='banner'>
        <img src={bannerBlog} alt="banner do blog AmadoMaker" />
      </div>
      <section className='section-area'>
        <section className='blog-area'>
          
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
        </section>
      </section>
      <Footer />
    </>
  )
}
