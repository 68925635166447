import React, { useEffect } from "react";
import './VideoHome.css';
import blackbox from '../../assets/images/blackbox.png'
import Botao from "components/Botao";
import { Link } from "react-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function VideoHome(){

  useEffect (()=> {
    AOS.init()
  }, [])

  return(
    <>
    <section className="section-video">
      <div className="hero-align">
        <div className="hero-content">
          <h1 data-aos='fade-down' data-aos-duration="2000" className="hero-titulo">amado<span className="h1-span">maker</span></h1>
          <p data-aos='fade-left' data-aos-duration="3000" className="hero-texto">O futuro da educação é digitalmente <span className="p-span">maker.</span></p> 
          <Link data-aos='fade-up' data-aos-duration="3000" to='home-area' spy={true} smooth={true} offset={-30} duration={1200}><Botao>Veja mais</Botao></Link>
        </div>
      </div>

    </section>
      
      <img className='blackbox' src={blackbox} alt="" />
      <video loop="true" autoplay="true" muted="true" src="" type="video/mp4"/>
    </>
  )
}
