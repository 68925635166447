import BotaoGrande from 'components/BotaoGrande';
import Footer from 'components/Footer';
import Header from 'components/Header';
import React from 'react';
import './EspiralTrilhas.css';
import EtapaDireita from 'components/EtapaDireita';
import EtapaEsquerda from 'components/EtapaEsquerda';
import imagem from '../../../assets/images/robo.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function EspiralTrilhas() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);


  return (
    <>
    <Header/>
    <section className='section-area'>
      <div className='produtos-text-area'>
        <h1 className='trilhas-h1' data-aos="fade" data-aos-duration="1500">Trilhas de aprendizagem</h1>
        <p className='produtos-texto' data-aos="fade" data-aos-duration="2000">
        As Trilhas de Aprendizagem são um conjunto de atividades educacionais e maker que combinam diversas áreas do conhecimento com as tecnologias de fabricação digital. <br /><br />
        
        Fundamentadas na Base Nacional Comum Curricular (BNCC), as atividades das Trilhas proporcionam uma aprendizagem através do "fazer" retomando a condição natural da experimentação, da curiosidade e da criatividade, permitindo àqueles que a praticam envolver-se em projetos aos quais possam criar coisas espontaneamente, indo além de apenas interagirem com as tecnologias. Contudo, nas atividades propostas, a curiosidade é epistemológica, uma “inquietação indagadora”, acompanhada de uma rigorosidade metódica a qual, só é possível, quando interligada ao conteúdo curricular.
        </p>
      </div>
      <div className='produtos-video' data-aos="fade" data-aos-duration="1500">
        <iframe width="100%" height="720" src="https://www.youtube.com/embed/bTQ5c93twtM" title="Inauguração Projeto E-Laborar Tauá-CE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>

      <div className='produtos-text-area width-100'>
          <h2 className='produtos-h2' data-aos="fade-down" data-aos-duration="1500">Etapas das atividades</h2>
          <EtapaEsquerda
            imagem={imagem} 
            titulo={'Introdução e Desafio:'} 
            texto={`Introdução ao tema da atividade;\nProposta do desafio.`}
          />
          <EtapaDireita
             imagem={imagem} 
             titulo={'Ideação e Planejamento:'} 
             texto={`Tempestade de ideias;\nElaboração de hipóteses;\nOrganização das ideias.`}
          />
          <EtapaEsquerda
            imagem={imagem} 
            titulo={'Experimentação e Prototipação:'} 
            texto={`Testes das hipóteses principais;\nConstrução de protótipos.`}
          />
          <EtapaDireita
             imagem={imagem} 
             titulo={'Validação e Modificação:'} 
             texto={`Verificação da funcionalidade dos protótipos;\nModificação e Otimização do protótipo.`}
          />
          <EtapaEsquerda
            imagem={imagem} 
            titulo={'Compartilhamento e Fechamento:'} 
            texto={`Comunicação dos resultados dos projetos finais;\nRelatos de dificuldades e sucessos;\nLimpeza e organização do espaço.`}
          />
      </div>
    </section>

    <div className='produtos-botao btn-trilhas'>
      <Link to="/contato"><BotaoGrande>Entre em contato conosco</BotaoGrande></Link>
    </div>
    <Footer/>
  </>
  )
}