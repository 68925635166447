import React from 'react'
import Footer from 'components/Footer'
import Header from 'components/Header'
import banner from '../../../assets/images/banner_plataforma.png';
import imagem from '../../../assets/images/plataforma-img.png'
import './PlataformaColaborativa.css';
import BotaoGrande from 'components/BotaoGrande';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function PlataformaColaborativa() {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  return (
    <>
      <Header/>
      <div className='banner-plataforma'>
        <img src={banner} alt="" />
      </div>
        
      <section className='section-area'>
        <div className='produtos-text-area width-100'>
          <h1 className='produtos-h1 plat-h1' data-aos="fade-down" data-aos-duration="1500">Plataforma Colaborativa</h1>
          <div className='plataforma-area'>
            <img src={imagem} alt=""  data-aos="zoom-in" data-aos-duration="1500"/>
            <div className='plataforma-direita'>
              <p className='plataforma-texto' data-aos="fade-left" data-aos-duration="2000">
              A Plataforma Colaborativa é construída para oferecer ferramentas de trabalho eficazes para o ensino híbrido para todas as etapas de ensino. O professor encontra arquivos de apoio e digitais para as aulas, além de vídeos explicativos. <br /><br />
              
              Com os ambientes personalizáveis, essa plataforma vem para suprir a necessidade de cada Unidade Escolar, neste atual cenário global, onde o Ensino a Distância se torna primordial para continuidade da educação. O professor pode disponibilizar seu próprio material dentro da plataforma, aplicar atividades, avaliações e desafios, tudo em tempo real, oferecendo segurança no processo e a viabilidade do ensino.
              </p>
              <div className='btn-plataforma'>
                <Link to="/contato"><BotaoGrande>Entre em contato conosco</BotaoGrande></Link>
              </div>
              
            </div>
            
          </div>
          

        </div>
      </section>
      <Footer/>
    </>
  )
}