import React from 'react';
import './ParceirosCard.css'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function ParceirosCard({ link, logoDoParceiro, nomeDoParceiro }) {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);


  return (
    <>
      <div className='parceiro-card' data-aos="zoom-in" data-aos-duration="1000">
      <Link to={link} target='_blank' style={{textDecoration: 'none'}}><img src={logoDoParceiro} alt="" /></Link>
        <div className='btn-parceiros'>
          <Link to={link} target='_blank' style={{textDecoration: 'none'}}><p>{nomeDoParceiro}</p></Link>
        </div>
      </div>
    </>
  )
}
