import React from 'react';
import './EquipeCard.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function EquipeCard({ imagem, nome, cargo }) {

  useEffect(()=> {
    AOS.init({once: true})
  },[]);

  
  return(
    <>
      <div className='equipe-card width-100'>
        <img src={imagem} alt={nome} data-aos="fade" data-aos-duration="500"/>
        <div className='equipecard-textos' data-aos-anchor="center" data-aos="fade top-top" data-aos-duration="2000">
          <h4 className='equipecard-nome' >{nome}</h4>
          <p className='equipecard-cargo'>{cargo}</p>
        </div>
      </div>
    </>
  )
}
