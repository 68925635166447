import React from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import './NossaEquipe.css'
import EquipeCard from 'components/EquipeCard';
import imgEquipe from '../../../assets/images/giftest.gif'

export default function NossaEquipe() {
  return (
    <>
      <Header/>
      <h1 className='produtos-h1' data-aos="fade" data-aos-duration="1000">Nossa Equipe</h1>
      <section className='section-area equipe-area'>
        <EquipeCard
          imagem={imgEquipe}
          nome={'Henrique Guerrato'}
          cargo={'Front-end developer'}
        />
      </section>
      
      <Footer/>
    </>
  )
}